*,
*::before,
*::after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}
button:focus {
  outline: none;
}
input::-moz-focus-inner,
fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}
ul,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}
p,
figure {
  margin: 0;
}
cite {
  font-style: normal;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input,
textarea,
select,
button {
  font-family: "Rajdhani", sans-serif;
}
html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html,
body,
#root {
  height: 100%;
}
body {
  font-family: "Rajdhani", sans-serif;
  color: #212121;
  background: #ffffff;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.25px;
  padding: 0;
  margin: 0;
}
div[role="presentation"] {
 max-width: 480px;
}
div[role="presentation"] > div[aria-hidden="true"] {
 max-width: 480px;
}
@media print {
  body {
    background-color: #ffffff;
  }
}

